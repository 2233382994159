<template>

<div>

	<com-title text="Billing" />

	<com-item route="Billing.Overview" text="Overview" />
	<com-item route="Billing.Payouts" text="Payouts" />
	<com-item route="Billing.Settings" text="Settings" />

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../../panel/common/Title'),
		'com-item': () => import('./../../panel/common/Item')
	}

}

</script>

<style scoped>

</style>
